.json-div {
    height: 550px;
}

.json-divEditor {
    height: 300px !important;
}

.ace_editor.ace-jsoneditor {
    min-height: 500px;
}